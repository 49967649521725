import NPLButton from '@/components/npl/NPLButton';
import React, { FC, useState } from 'react';
import { usePathname } from 'next/navigation';
import config from '@/utility/config';
import ShareModal from '../ShareModal/ShareModal';
import { getEntityTitleAndLabel } from './constants';
import classNames from 'classnames';
import Icon from '@/components/npl/Icon';
import NextImage from '../NextImage';
import Link from 'next/link';

type Props = {
  entityTitle: string;
  entityType: 'challenge' | 'product' | 'event' | 'session';
  darkMode: boolean;
  communityImageSrc: string;
  communityTitle: string;
  communityLink: string;
  isLoggedIn: boolean;
};

const BackAndShareBtnBar: FC<Props> = ({
  entityTitle,
  entityType,
  darkMode = false,
  communityImageSrc,
  communityTitle,
  communityLink,
  isLoggedIn
}) => {
  const { title, label } = getEntityTitleAndLabel()[entityType];
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const pathName = usePathname();

  const shareUrl = `${config.siteBaseUrl}${pathName}`;
  return (
    <>
      <div className="flex items-center justify-between">
        {!isLoggedIn ? (
          <Link
            href={communityLink}
            className={classNames(
              'rounded-100 flex items-center py-6 pl-4 pr-8 hover:cursor-pointer transition-all',
              {
                'bg-npl-neutral-light-alpha-3 hover:bg-npl-neutral-light-alpha-4':
                  !darkMode,
                'bg-npl-neutral-dark-alpha-3 hover:bg-npl-neutral-dark-alpha-4':
                  darkMode
              }
            )}>
            <Icon
              name="chevron-left"
              width={20}
              height={20}
              fill={darkMode ? '#F5F5F5' : '#1B1B18'}
            />
            <div className="relative w-16 h-16 ml-2 mr-8">
              <NextImage
                className="rounded-4"
                mobileImgProps={{
                  src: communityImageSrc,
                  layout: 'fill'
                }}
              />
            </div>
            <div
              className={classNames('text-label-sm font-medium', {
                'text-npl-text-primary-on-dark': darkMode,
                'text-npl-text-primary-on-light': !darkMode
              })}>
              {communityTitle}
            </div>
          </Link>
        ) : (
          <NPLButton
            leadIcon="chevron-left"
            hierarchy="neutral_secondary"
            size="xs"
            rounded
            link={communityLink}
            darkMode={darkMode}
          />
        )}

        <NPLButton
          leadIcon="share-01"
          hierarchy="neutral_secondary"
          size="xs"
          rounded
          onClick={() =>
            navigator && !navigator.canShare
              ? setIsShareModalOpen(true)
              : navigator.share({
                  title: entityTitle,
                  url: shareUrl
                })
          }
          darkMode={darkMode}
        />
      </div>
      <ShareModal
        shareUrl={shareUrl}
        title={title}
        open={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        label={label}
        shareText={entityTitle}
      />
    </>
  );
};

export default BackAndShareBtnBar;
