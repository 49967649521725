import { t } from '@/utility/localization';

export const getEntityTitleAndLabel = () => ({
  challenge: {
    title: t('share-challenge'),
    label: t('challenge-page-link')
  },
  event: {
    title: t('share-your-event'),
    label: t('event-public-page-link')
  },
  session: {
    title: t('share-your-1-1-session'),
    label: t('1-1-session-public-page-link')
  },
  product: {
    title: t('share-your-product'),
    label: t('product-page-link')
  }
});
