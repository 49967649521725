import React, { useEffect, useState } from 'react';

import { openInNewTab } from '@/utility/helpers';
import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Modal from '@/components/common/Modal';
import ToolTip from '@/components/common/ToolTip';
import Icon from '@/components/npl/Icon';

import { copyTextToClipboard } from '@/pages/portal/utils/events';

import PopOverModal from '../PopOverModal';
import { showToast } from '../ToastContainer';

const ShareLink = ({ icon, href, title, ...rest }) => {
  return (
    <ToolTip text={title} customClassName="w-[auto] text-center">
      <a
        href={href}
        className="flex items-center justify-center rounded-full border border-neutral-80 p-12"
        target="_blank"
        rel="noreferrer noopener"
        {...rest}>
        {icon}
      </a>
    </ToolTip>
  );
};

const ShareModal = ({
  open = false,
  onClose,
  title,
  renderOverview = null,
  shareText,
  shareUrl,
  label,
  onShareClick = null,
  checkoutLink = '',
  checkoutLabel = '',
  checkoutToolTip = ''
}) => {
  const { isGtEqMd } = useWindowWidthContext();
  const encodedShareText = encodeURIComponent(shareText);
  const encodedShareUrl = encodeURIComponent(shareUrl);
  const [nativeShareSupported, setNativeShareSupported] = useState(false);
  useEffect(() => {
    setNativeShareSupported(navigator && !!navigator.share);
  }, []);

  const handleShareClick = (params) => {
    if (onShareClick) onShareClick(params);
  };

  const onNativeShareClick = () => {
    if (nativeShareSupported) {
      navigator.share({
        title: shareText,
        url: shareUrl
      });
    }

    handleShareClick({ mode: 'native-share' });
  };

  const onCopyShareUrl = () => {
    copyTextToClipboard({ text: shareUrl });
    handleShareClick({ mode: 'copy-link' });
    showToast({ text: t('copied') });
  };

  const onCopyCheckoutLink = () => {
    copyTextToClipboard({ text: checkoutLink });
    handleShareClick({ mode: 'copy-link' });
    showToast({ text: t('copied') });
  };

  const renderContent = () => {
    return (
      <>
        <h3 className="text-24 font-medium tracking-[-0.019em] text-neutral-10">
          {title}
        </h3>
        {renderOverview && renderOverview?.()}
        <p className="mt-24 text-label-md font-medium text-npl-text-icon-on-light-surface-secondary">
          {t('share-caption')}
        </p>
        <div className="mt-12 flex gap-12">
          {nativeShareSupported && (
            <ShareLink
              title={t('share')}
              onClick={onNativeShareClick}
              icon={<Icon name="share-01" />}
            />
          )}
          <ShareLink
            title={t('whatsapp2')}
            href={`https://api.whatsapp.com/send?text=${encodedShareText}+${encodedShareUrl}`}
            icon={<Icon path="social" name="whatsapp-neutral" />}
            onClick={() => handleShareClick({ mode: 'whatsapp' })}
          />
          <ShareLink
            title={t('twitter')}
            href={`https://twitter.com/intent/tweet?text=${encodedShareText}&url=${encodedShareUrl}`}
            icon={<Icon path="social" name="twitter-neutral" />}
            onClick={() => handleShareClick({ mode: 'twitter' })}
          />
          <ShareLink
            title={t('linkedin2')}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareUrl}`}
            icon={<Icon path="social" name="linkedin-neutral" />}
            onClick={() => handleShareClick({ mode: 'linkedin' })}
          />
          <ShareLink
            title={t('facebook')}
            href={`https://www.facebook.com/sharer.php?u=${encodedShareUrl}`}
            icon={<Icon path="social" name="facebook-neutral" />}
            onClick={() => handleShareClick({ mode: 'facebook' })}
          />
        </div>
        <div className="mt-24">
          <div className="mb-8 text-16 font-medium text-neutral-10">
            {label}
          </div>
          <div className="flex items-center justify-between gap-16 rounded-12 bg-neutral-97 p-16">
            <div
              onClick={() => openInNewTab(shareUrl)}
              className="truncate bg-transparent text-neutral-50 outline-none">
              {shareUrl}
            </div>
            <ToolTip
              text={t('copy-link1')}
              customClassName="w-[90px] text-center">
              <button
                onClick={onCopyShareUrl}
                className="flex items-center">
                <Icon name="copy" width={18} height={18} />
              </button>
            </ToolTip>
          </div>
        </div>
        {checkoutLink && (
          <div className="mt-24">
            <div className="mb-8 flex flex-row items-center gap-4 text-16 font-medium text-neutral-10">
              {checkoutLabel}
              <ToolTip
                text={checkoutToolTip}
                customClassName="w-[90px] text-center">
                <Icon name="info-circle" height={16} />
              </ToolTip>
            </div>
            <div className="flex items-center justify-between gap-16 rounded-12 bg-neutral-97 p-16">
              <div
                onClick={() => openInNewTab(checkoutLink)}
                className="truncate bg-transparent text-neutral-50 outline-none">
                {checkoutLink}
              </div>
              <ToolTip
                text={t('copy-link1')}
                customClassName="w-[90px] text-center">
                <button
                  onClick={onCopyCheckoutLink}
                  className="flex items-center">
                  <Icon name="copy" width={18} height={18} />
                </button>
              </ToolTip>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {isGtEqMd ? (
        <Modal
          open={open}
          onClose={onClose}
          size="md"
          customCloseIconClass="!top-28 !right-12"
          customContainerClass="!rounded-20 !max-w-[640px]">
          {renderContent()}
        </Modal>
      ) : (
        <PopOverModal open={open} onClose={onClose}>
          {renderContent()}
        </PopOverModal>
      )}
    </>
  );
};

export default ShareModal;
