import * as yup from 'yup';

import {
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE,
  defaultHostProfileImage
} from '../events/constants';

import config from '@/utility/config';
import { format } from 'date-fns';
import sessionStorageService from '@/utility/sessionStorageService';
import {
  getCommunityCheckoutRoute,
  getEntityPublicPageUrl
} from '@/utility/routesHelper';
import { ENTITY_SLUGS_PREFIX } from '@/utility/helpers';
import { formatDateTimeWithLocale } from '@/utility/localization';
import { getTimezoneId } from '@/utility/dateHelper';

export const isValidUrl = ({ url }) => {
  if (!url) return false;

  let schema = yup.string().url();
  return schema.isValidSync(url);
};

export const eventDataFormatter = ({ event, type }) => {
  try {
    const startDate = new Date(event?.startTime);
    const endDate = new Date(event?.endTime);
    const timingStr = `${formatDateTimeWithLocale(
      startDate,
      'hh:mm aaa'
    )} - ${formatDateTimeWithLocale(endDate, 'hh:mm aaa')}`;
    const timezone = getTimezoneId();
    const startDateDetails = {
      day: format(startDate, 'dd'),
      month: format(startDate, 'MMM'),
      weekDay: format(startDate, 'iii')
    };

    const getMeetingLocation = (eventType) => {
      switch (eventType) {
        case EVENT_TYPE_LIVE:
          return {
            icon: 'zoom-session-live',
            text: 'Online',
            isLink: true,
            link: event?.liveLink
          };
        case EVENT_TYPE_IN_PERSON: {
          const isLink = isValidUrl({
            url: event?.inPersonLocation
          });

          return {
            icon: 'place',
            text: isLink ? 'Location' : event?.inPersonLocation,
            isLink: isLink,
            link: event?.inPersonLocation
          };
        }
      }
    };

    const getHostImage = ({ url }) => {
      const isValid = isValidUrl({ url });

      if (!isValid) return defaultHostProfileImage;
      return url;
    };
    return {
      id: event?._id,
      type,
      startDateDetails,
      title: event?.title,
      shortUrl: event?.shortUrl,
      host: {
        name: `${event?.host?.firstName} ${event?.host?.lastName || ''}`,
        imageData: {
          mobileImgProps: {
            src: getHostImage({ url: event?.host?.profileImage }),
            layout: 'fixed',
            width: 32,
            height: 32,
            objectFit: 'cover'
          }
        }
      },
      eventDetails: [
        { icon: 'clock', text: timingStr, isLink: false, isBold: true },
        { icon: 'globe', text: timezone, isLink: false },
        { ...getMeetingLocation(event?.type) }
      ]
    };
  } catch (error) {
    console.error('Error in eventDataFormatter: ', error);
    return {};
  }
};

export const copyTextToClipboard = ({ text }) => {
  try {
    //if the browser support the navigator clipboard api
    if (
      navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText
    ) {
      navigator.clipboard.writeText(text).catch((e) => {
        throw e; // this is a sync function so we need to rethrow error
      });
      return;
    }

    //else the obvious method
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  } catch (e) {
    console.error('Copy to clipboard did not work!', e);
  }
};

export const getEventEditUrl = ({ id, activeCommunityId }) => {
  return `/portal/events/edit/${id}?activeCommunityId=${activeCommunityId}`;
};

export const getEventManagementUrlPage = ({
  id,
  activeCommunityId = ''
}) => {
  let route = `/portal/events/manage/${id}`;
  if (activeCommunityId) {
    route = `${route}?activeCommunityId=${activeCommunityId}`;
  }
  return route;
};

export const resolveEventSlugUrl = (communityLink, slug) => {
  const communityLinkWithoutSlash = communityLink.replace(/\//g, '');
  const slugWithoutSlash = slug.replace(/\//g, '');
  return getEntityPublicPageUrl({
    communitySlug: communityLinkWithoutSlash,
    entityType: ENTITY_SLUGS_PREFIX.EVENT,
    entitySlug: slugWithoutSlash,
    fullUrl: true
  });
};

export const resolveBaseUrl = (url) => {
  if (!url) return null;
  const urlWithoutStartingSlash = url.replace(/^\//g, '');

  if (config.siteBaseUrl) {
    return `${config.siteBaseUrl}/${urlWithoutStartingSlash}`;
  }
  return `https://nas.io/${urlWithoutStartingSlash}`;
};

export const setLastVisitedEvent = (eventInfo, communityInfo) => {
  sessionStorageService.setItem(
    'lastVisitedEvent',
    JSON.stringify({
      eventInfo,
      communityInfo
    })
  );
};

export const getLastVisitedEvent = () => {
  const lastVisitedEvent =
    sessionStorageService.getItem('lastVisitedEvent');
  if (!lastVisitedEvent) {
    return null;
  }
  return JSON.parse(lastVisitedEvent);
};

export const resolveCommunityUrl = (communitySlug) => {
  const communitySlugWithoutSlash = communitySlug.replace(/\//g, '');
  if (config.siteBaseUrl) {
    return `${config.siteBaseUrl}/${communitySlugWithoutSlash}`;
  }
  return `https://nas.io/${communitySlugWithoutSlash}`;
};

export const getEventCheckoutLink = (
  communityData,
  eventObjectId,
  sourceInfo,
  entityDiscountCode
) => {
  if (eventObjectId && communityData?._id) {
    return getCommunityCheckoutRoute({
      type: 'community',
      communityCode: communityData?.code,
      communityId: communityData?._id,
      baseCurrency: communityData?.baseCurrency,
      requestor: 'event',
      actionType: 'event',
      sourceInfo,
      entityDiscountCode
    });
  }
};
